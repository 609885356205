import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import List from "../components/List"
import {
  certificationList,
  certificationCards,
} from "../data/certification-data"
import CertificationItem from "../components/CertificationItem"
import TitlePage from "../components/TitlePage"

export default function Certificacion({ location }) {
  const path = [
    { label: "Inicio", to: "/" },
    { label: "Certificaciones - Definición " },
  ]
  const renderCertificationItems = () => {
    return (
      certificationCards.length &&
      certificationCards.map(item => (
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 p-0">
          <CertificationItem
            className={item.className}
            link={item.link}
            image={item.image}
            logo={item.logo}
            title={item.title}
            titleHTML={item.titleHTML}
          />
        </div>
      ))
    )
  }
  return (
    <Layout>
      <TitlePage title="Certificaciones" url={location.pathname} />
      <Hero image="/imgs/certificaciones-hero.jpg" text="Certificaciones" />
      <div className="container950">
        <div className="Certification">
          <Breadcrumb path={path} />
          <TitleBlock
            title="Definición de las certificaciones"
            icon="far fa-file-alt"
          />
          <div className="Certification-content">
            <div className="efpa-content row efpa-row">
              <div className="col-12 col-lg-6 Certification-padding-r">
                <span className="Certification-text">
                  Las certificaciones profesionales miden competencias y
                  conocimientos basándose en programas basados en el ejercicio
                  profesional. Son evaluados por comités independientes y son
                  otorgadas por entidades reconocidas en el mercado sin
                  orientación comercial.
                </span>
                <span className="Certification-text">
                  Las certificaciones profesionales están diferenciadas y
                  separadas de los formadores y nacen con la idea de convertirse
                  en estándares de ejercicio profesional. La diferencia con las
                  titulaciones académicas es que NO son de por vida; una vez
                  obtenidas se debe garantizar anualmente que se ha realizado la
                  suficiente formación continua para mantenerse actualizado
                  profesionalmente.
                </span>
              </div>
              <div className="col-12 col-lg-6 Certification-padding-l">
                <span className="Certification-text">
                  Estas certificaciones profesionales están basadas en datos
                  reales de lo que constituye la práctica de la planificación
                  financiera.
                </span>
                <span className="Certification-text">
                  Los exámenes para la obtención de las certificaciones se basan
                  en el análisis del puesto de trabajo, están vinculados a las
                  necesidades de desempeño y son apropiadas para la industria de
                  servicios financieros.
                </span>
                <span className="Certification-text">
                  Los programas de contenidos exigidos para los exámenes, son
                  comunes en toda Europa.
                </span>
                <span className="Certification-text">
                  EFPA, en toda Europa, acredita y certifica a los profesionales
                  de la asesoría y planificación financiera personal con estos
                  certificados:
                </span>
              </div>
              <div className="col-12 col-lg-6 Certification-padding-r">
                <img
                  className="Certification-image"
                  src="/imgs/certificaciones-efpa.jpg"
                  alt="pyramid efpa"
                />
              </div>
              <div className="col-12 col-lg-6 Certification-padding-l">
                <span className="Certification-title">
                  Ventajas de la certificación
                </span>
                <span className="Certification-text">
                  Las certificaciones de EFPA ofrecen una serie de ventajas para
                  los profesionales que las poseen:
                </span>
                <List
                  className="Certification-list"
                  items={certificationList}
                  type="ol"
                />
              </div>
            </div>
            <div className="Certification-wrapper">
              <TitleBlock
                title="Certificaciones de EFPA (EIA, EIP, EFA, EFP, LCCI, ESG)"
                icon="far fa-file-alt"
              />
              <div className="row efpa-row">{renderCertificationItems()}</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
