import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Fragment } from "react"
import parse from "react-html-parser"

export default function CertificationItem(props) {
  const { className, link, image, logo, title, price, titleHTML } = props

  const renderPrice = () => {
    if (!price) return <Fragment />
    return <p className="CertificationItem-price">{price}</p>
  }

  return (
    <div
      className={`CertificationItem ${
        price ? "" : "withoutPrice"
      } ${className}`}
    >
      <Link to={link} className="CertificationItem-link">
        <img className="CertificationItem-image" src={image} alt="" />
        <div className="CertificationItem-content">
          <div className="CertificationItem-header">
            <div className="CertificationItem-logo">{logo}</div>
            <h4 className="CertificationItem-title">
              {titleHTML ? parse(title) : title}
            </h4>
          </div>
          <div className="CertificationItem-body">
            {renderPrice()}
            <span className="CertificationItem-btn">Ver</span>
          </div>
        </div>
      </Link>
    </div>
  )
}

CertificationItem.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
  logo: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.string,
  titleHTML: PropTypes.bool,
}

CertificationItem.defaulrPropTypes = {
  className: "",
  link: "",
  image: "",
  logo: "",
  title: "",
  price: "",
}
