export const certificationData = [
  {
    text: `Las certificaciones profesionales miden competencias y conocimientos basándose en programas basados en el ejercicio profesional. Son evaluados por comités independientes y son otorgadas por entidades reconocidas en el mercado sin orientación comercial.`,
  },
  {
    text: `Las certificaciones profesionales están diferenciadas y separadas de los formadores y nacen con la idea de convertirse en estándares de ejercicio profesional. La diferencia con las titulaciones académicas es que NO son de por vida; una vez obtenidas se debe garantizar anualmente que se ha realizado la suficiente formación continua para mantenerse actualizado profesionalmente.`,
  },
]

export const certificationDefinition = [
  {
    text: `Estas certificaciones profesionales están basadas en datos reales de lo que constituye la práctica de la planificación financiera.`,
  },
  {
    text: `Los exámenes para la obtención de las certificaciones se basan en el análisis del puesto de trabajo, están vinculados a las necesidades de desempeño y son apropiadas para la industria de servicios financieros.`,
  },
  {
    text: `Los programas de contenidos exigidos para los exámenes, son comunes en toda Europa.`,
  },
  {
    text: `EFPA, en toda Europa, acredita y certifica a los profesionales de la asesoría y planificación financiera personal con estos certificados:`,
  },
]

export const certificationList = [
  {
    content: `Ampliación permanente de sus conocimientos para progresar profesionalmente`,
  },
  {
    content: `Avanzar en la calidad de servicio y en la satisfacción y fidelidad de los clientes`,
  },
  {
    content: `Adquisición de prestigio profesional y empleabilidad`,
  },
  {
    content: `Adaptación a las presentes y futuras exigencias regulatorias de cualificación profesional`,
  },
]

export const certificationOptions = [
  {
    text: "Definición",
    link: "/certificacion",
    icon: "",
  },
  {
    text: "Programas",
    link: "/",
    icon: "",
    options: [
      {
        text: "España (Guía de certificación EFP, EFA, EIP y EIA)",
        link: "/certificacion-espana",
      },
      {
        text: "Europa (Core competence EFP, EFA, EIP y EIA)",
        link: "/certificacion-europa",
      },
    ],
    isCollapse: true,
  },
  {
    text: "Cursos preparatorios para los exámenes",
    link: "/",
    icon: "",
    options: [
      {
        text: "Centros formativos acreditados EFA",
        link: "/centros-formativos-acreditados-efa",
      },
      {
        text: "Centros formativos acreditados EFP",
        link: "/centros-formativos-acreditados-efp",
      },
    ],
    isCollapse: true,
  },
  {
    text: "Exámenes",
    link: "/",
    icon: "",
    options: [
      {
        text: "Certificación EFP",
        link: "/examenes-certificacion-efp",
        icon: "fas fa-sitemap",
      },
      {
        text: "Certificación EFA",
        link: "/examenes-certificacion-efa",
        icon: "fas fa-user-tie",
      },
      {
        text: "Certificación EIP",
        link: "/examenes-certificacion-eip",
        icon: "fas fa-certificate",
      },
      {
        text: "Certificación EIA",
        link: "/examenes-certificacion-eia",
        icon: "far fa-id-card",
      },
      {
        text: "Certificación EFPA-LCCI",
        link: "/efpa-lcci",
        icon: "far fa-id-card",
      },
      {
        text: "Certificación EFPA-ESG",
        link: "/efpa-esg",
        icon: "fab fa-envira",
        borderBottom: true,
      },
      {
        text: "¿Cómo inscribirme a los exámenes de EFPA España?",
        link: "/como-inscribirme-examenes-efpa",
      },
      {
        text: "Próximas convocatorias de exámenes",
        link: "/fechas-examenes",
      },
      {
        text: "Convalidaciones con otras certificaciones",
        link: "/reconocimientos-otras-certificaciones",
      },
    ],
    isCollapse: true,
  },
  {
    text: "Asociarme a EFPA España",
    link: "/",
    icon: "",
    options: [
      {
        text: "¿Cómo hacerlo?",
        link: "/como-hacerme-asociado-efpa",
      },
      {
        text: "Código ético y Declaración de Honorabilidad",
        link: "/codigo-etico-y-declaracion-de-honorabilidad",
      },
      {
        text: "Recertificación",
        link: "/recertificacion",
      },
      {
        text: "Ventajas para jubilados y desempleados",
        link: "/ventajas-para-jubilados-y-desempleados",
      },
    ],
    isCollapse: true,
  },
]

export const listAttributes = [
  {
    image: "/imgs/attributes/image-attribute1.svg",
    text: "Presencial, Streaming",
  },
  { image: "/imgs/attributes/image-attribute2.svg", text: "201 horas" },
  { image: "/imgs/attributes/image-attribute3.svg", text: "28/09/2021" },
]

export const certificationCards = [
  {
    link: "/examenes-certificacion-eia",
    image: "/imgs/certification-item.jpg",
    titleHTML: true,
    logo: "EIA",
    title:
      "Exámenes certificación EFPA <em>European Investment Assistant</em> (EIA)",
    price: "121€ IVA Incluido",
  },
  {
    link: "/examenes-certificacion-eip",
    image: "/imgs/certification-item.jpg",
    logo: "EIP",
    titleHTML: true,
    title:
      "Exámenes certificación EFPA <em>European Investment Practitioner</em> (EIP)",
    price: "181,50€ IVA Incluido",
  },
  {
    link: "/examenes-certificacion-efa",
    image: "/imgs/certification-item.jpg",
    logo: "EFA",
    titleHTML: true,
    title:
      "Exámenes certificación EFPA <em>European Financial Advisor</em> (EFA)",
    price: "272,25€ IVA Incluido",
  },
  {
    link: "/examenes-certificacion-efp",
    image: "/imgs/certification-item.jpg",
    logo: "EFP",
    titleHTML: true,
    title:
      "Exámenes certificación EFPA <em>European Financial Planner</em> (EFP)",
    price: "665,5€ IVA Incluido",
  },
  {
    className: "withoutPrice",
    link: "/efpa-lcci",
    image: "/imgs/certification-item.jpg",
    logo: "LCCI",
    title:
      "Certificación Asesor/Informador EFPA-LCCI - Ley de Contrato de Crédito Inmobiliario",
  },
  {
    link: "/efpa-esg",
    image: "/imgs/certification-item.jpg",
    logo: "ESG",
    titleHTML: true,
    title: "Certificación EFPA ESG <em>Advisor™</em>",
    price: "121€ IVA Incluido",
  },
]
